<template>
  <mds-data-table
    v-bind="$props"
    v-on="$listeners"
  >
    <template v-slot:body>
      <mds-data-table-body
        :header-configs="headerConfigs"
        :row-data="renderedRowData"
      >
        <mds-data-table-row
          v-for="(row, index) in renderedRowData"
          :id="row.id"
          :key="row.id"
          :row-index="index"
          :header-configs="headerConfigs"
          :row-data="row"
        >
          <template v-slot:body-cell="cellData">
            <template v-if="cellData.headerConfig.fieldName === 'action'">
              <mds-button
                :id="getActionButtonId(cellData.rowData.id)"
                type="button"
                variation="icon-only"
                icon="caret-circle-down"
                size="small"
                @click="toggleActionButtonPopover(cellData)"
              >
                Open Popover
              </mds-button>
            </template>
            <template v-if="cellData.headerConfig.fieldName === 'fileStatus'">
              <span
                :style="`--status-color: var(--color--${cellData.rowData[cellData.headerConfig.fieldName].split(' ').join('-').toLowerCase()});`"
                class="operations-historical__table-status"
              >
                {{ cellData.rowData[cellData.headerConfig.fieldName] }}
              </span>
            </template>
          </template>
        </mds-data-table-row>
      </mds-data-table-body>
    </template>
    <template v-slot:footer>
      <!--
        Slot in footer content so it can be observed.
        If the footer has been reached, we can render additional rows.
      -->
      <span v-intersect="intersectArgs" />
    </template>
  </mds-data-table>
</template>

<script>
// Transition
import { MdsDataTable, MdsDataTableBody } from '@mds/data-table'
import MdsDataTableRow from '@mds/data-table/src/data-table-body/data-table-row'
import { MdsButton } from '@mds/button'
import { ACTION_BTN_POPOVER_EVENT_EMITTER } from '../../../shared/constants/event-emitters'

const MdsDataTableProps = MdsDataTable.props

export default {
  components: {
    MdsDataTable,
    MdsDataTableBody,
    MdsDataTableRow,
    MdsButton,
  },
  props: {
    ...MdsDataTableProps,
    /**
     * The number of additional rows to render when reaching the end of the table.
     */
    batch: {
      type: Number,
      default: 10,
      validator: value => value > 0,
    },
    /**
     * The initial number of rows to render. (revisit this approach—could potentially check how many rows would fill viewport)
     */
    bench: {
      type: Number,
      default: 50,
      validator: value => value >= 50,
    },
    /**
     * The vertical offset from root element's bounding box.
     *
     * An offset of 200 would intersect 200px before the element actually intersects with the viewport.
     * An offset of -200 would intersect 200px after the element actually intersects with the viewport.
    */
    offset: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      numberOfRowsToRender: this.bench,
      intersectArgs: {
        handler: this.onElementObserved,
        options: {
          root: null,
          rootMargin: `0px 0px ${this.offset}px 0px`,
          threshold: 0,
        },
      },
    }
  },
  computed: {
    allRowsRendered () {
      return this.numberOfRowsToRender >= this.rowData.length
    },
    renderedRowData () {
      return this.rowData.slice(0, this.numberOfRowsToRender)
    },
  },
  methods: {
    onElementObserved (entries) {
      if (entries[0].isIntersecting && !this.allRowsRendered) {
        this.numberOfRowsToRender = this.numberOfRowsToRender + this.batch
      }
    },
    getActionButtonId (id) {
      return `action-btn-row-${id}`
    },
    toggleActionButtonPopover (cellData) {
      const triggerPopover = this.getActionButtonId(cellData.rowData.id)
      this.$emit(ACTION_BTN_POPOVER_EVENT_EMITTER, { cellData, triggerPopover })
    },
  },
}
</script>
