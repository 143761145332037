<template>
  <mds-modal
    v-model="toggleModal"
    aria-labelledby="save-filters"
    action-required
    :width="'600px'"
  >
    <mds-section
      border-position="bottom"
      bold
    >
      <template v-slot:mds-section-title>
        <span id="save-filters">{{ title }}</span>
      </template>
      <template v-slot:mds-section-actions>
        <mds-button-container right-aligned>
          <mds-button
            class="modal__btn-cancel"
            variation="secondary"
            @click="$emit('close-modal', $event)"
          >
            Cancel
          </mds-button>
          <mds-button
            variation="primary"
            :disabled="disableSaveBtn"
            @click="$emit('action-modal-button', $event)"
          >
            Save
          </mds-button>
        </mds-button-container>
      </template>
      <mds-section
        border="none"
        :title="description"
        class="modal__section"
        :size="6"
      >
        <mds-form>
          <mds-input
            label="Link Name"
            placeholder="Example Name"
            v-bind="$attrs"
            @input="$emit('input', $event)"
          />
        </mds-form>
      </mds-section>
    </mds-section>
  </mds-modal>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button'
import MdsModal from '@mds/modal'
import MdsInput from '@mds/input'
import MdsSection from '@mds/section'
import MdsForm from '@mds/form'

export default {
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsInput,
    MdsSection,
    MdsForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    toggleModal: {
      type: Boolean,
      required: true,
    },
    disableSaveBtn: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.modal__btn-cancel{
  margin-right: $mds-space-half-x;
}
.modal__section{
  padding: $mds-space-2-and-a-half-x;
}
</style>
