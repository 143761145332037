/**
 * A mixin for initializing and updating Vue data properties via the
 * sessionStorage API. When mounted, the mixin will check to see if
 * the provided data property has a value currently stored in the session.
 * If it does, the data property is updated with that value. Similarly,
 * any time that data property changes, the session storage property value
 * is updated.
 *
 * @param {Object} options
 * @param {String[]} options.properties - Data properties to store and update in session
 * @param {String} options.suffix - Suffix to add to the session object's key
 * @param {Boolean} options.setUrlId - Flag to add an additional suffix based on the URL id of each page
 *
 * Note: move to Vue 3's composition API when available.
 */

import { isEmpty, startCase } from 'lodash'
import { hasAllElements } from '../../shared/utils'
import { CACHE_FIRST } from '../graqhql/fetch-policies'
import {
  GET_PROVIDERS,
  GET_FIDUCIARIES,
  GET_PROGRAMS,
  GET_PRODUCTS,
  GET_MENUS,
  GET_PLAN_STATUSES,
} from '../graqhql/queries'
import {
  OPERATIONS_HISTORICAL_NAME,
  DATA_PLANS_NAME,
  DATA_PLANS_DETAILS_NAME,
  PROCESS_METHODS,
  PROCESS_TYPES,
  FILE_EXTENSIONS, PROCESS_STAGES, PROCESS_STATUS,
} from '../../shared/constants/filters'

const CLEAR_FILTER_DATA = []

export default ({ properties, suffix = 'session', setUrlId }) => {
  // Construct Vue's "watch" object
  const watchers = properties.reduce((watchObj, property) => {
    watchObj[property] = {
      handler (newVal) {
        // Stringify to handle arrays and objects as well
        // setUrlId flag is initially created to persist the filter values of each Plan Details page based on its planId
        switch (true) {
          case setUrlId:
            return sessionStorage.setItem(`${property}___${suffix}___${this.$route.params.id}`, JSON.stringify(newVal))
          default:
            return sessionStorage.setItem(`${property}___${suffix}`, JSON.stringify(newVal))
        }
      },
    }
    return watchObj
  }, {})

  return {
    watch: { ...watchers },
    data () {
      return {
        isDataPlansPage: suffix === DATA_PLANS_NAME,
        isOperationHistoricalPage: suffix === OPERATIONS_HISTORICAL_NAME,
        validSessionStorageProperties: [],
      }
    },
    mounted () {
      this.$nextTick(function () {
        this.checkSessionStorageData()
      })
    },
    methods: {
      getSessionStorageFiltersData () {
        // Retrieve session storage data only for Operations Historical or Data Plans.
        return Object.keys(sessionStorage).reduce(function (obj, str) {
          const sessionObject = {}
          sessionObject[str] = JSON.parse(sessionStorage.getItem(str))
          str.includes(suffix) && obj.push(sessionObject)
          return obj
        }, [])
      },
      showFilterDataError (filtersData, sessionStorageData) {
        // Show error notification if filter values coming from session storage doesn't exist.
        // If values from session storage match with filter values from backend, we collect them in validSessionStorageProperties array.
        const showNotification = (filterName, filterValues, allFilterData) => {
          const isWrongValue = !hasAllElements(filterValues, allFilterData)
          if (isWrongValue) {
            this[filterName] = CLEAR_FILTER_DATA
            this.$notifications.error({
              text: `The current ${startCase(filterName)} filter has values that are no longer available for filtering, and has been reset to filter on its default values.`,
              tinted: true,
              dismissDelay: 5000,
            })
          } else {
            this.validSessionStorageProperties.push(filterName)
          }
        }

        sessionStorageData.forEach(filterObj => {
          Object.entries(filterObj).forEach(([savedFilterName, savedFilterValues]) => {
            filtersData.map(({ filterName, allFilterData }) => {
              if (
                savedFilterName.includes(filterName) &&
                !savedFilterName.includes(DATA_PLANS_DETAILS_NAME) &&
                !isEmpty(savedFilterValues)
              ) {
                showNotification(filterName, savedFilterValues, allFilterData)
              }
            })
          })
        })
      },
      async checkSessionStorageData () {
        // For Operations Historical and Data Plans pages we need to check if session storage data coming from Quick Links is correct.
        if (this.isDataPlansPage || this.isOperationHistoricalPage) {
          const sessionStorageData = this.getSessionStorageFiltersData()
          const [
            providerResponse,
            fiduciariesResponse,
            programsResponse,
            productsResponse,
            menusResponse,
            planStatusesResponse,
          ] = await Promise.all([
            this.$apollo.query({ query: GET_PROVIDERS, variables: { selectedView: this.selectedView }, fetchPolicy: CACHE_FIRST }),
            this.$apollo.query({ query: GET_FIDUCIARIES, fetchPolicy: CACHE_FIRST }),
            this.isDataPlansPage && this.$apollo.query({ query: GET_PROGRAMS, fetchPolicy: CACHE_FIRST }),
            this.isDataPlansPage && this.$apollo.query({ query: GET_PRODUCTS, fetchPolicy: CACHE_FIRST }),
            this.isDataPlansPage && this.$apollo.query({ query: GET_MENUS, fetchPolicy: CACHE_FIRST }),
            this.isDataPlansPage && this.$apollo.query({ query: GET_PLAN_STATUSES, fetchPolicy: CACHE_FIRST }),
          ])

          const providersArray = [...providerResponse.data.providers.map(({ code }) => code)]
          const fiduciariesArray = [...fiduciariesResponse.data.fiduciaries.map(({ code }) => code)]
          const programsArray = this.isDataPlansPage && [...programsResponse.data.programs.map(({ code }) => code)]
          const productsArray = this.isDataPlansPage && [...productsResponse.data.products.map(({ code }) => code)]
          const menusArray = this.isDataPlansPage && [...menusResponse.data.menus.map(({ name }) => name)]
          const planStatusesArray = this.isDataPlansPage && [...planStatusesResponse.data.planStatuses.map(({ name }) => name)]

          // Map to associate filter name with their respective whole set of data.
          const FILTERS_DATA_MAP = {
            [OPERATIONS_HISTORICAL_NAME]: [
              { filterName: 'selectedProvider', allFilterData: providersArray },
              { filterName: 'selectedFiduciary', allFilterData: fiduciariesArray },
              { filterName: 'selectedProcessMethod', allFilterData: PROCESS_METHODS },
              { filterName: 'selectedProcessType', allFilterData: PROCESS_TYPES },
              { filterName: 'selectedProcessStage', allFilterData: PROCESS_STAGES },
              { filterName: 'selectedProcessStatus', allFilterData: PROCESS_STATUS },
              { filterName: 'selectedFileExtension', allFilterData: FILE_EXTENSIONS },
            ],
            [DATA_PLANS_NAME]: [
              { filterName: 'selectedProvider', allFilterData: providersArray },
              { filterName: 'selectedFiduciary', allFilterData: fiduciariesArray },
              { filterName: 'selectedProgram', allFilterData: programsArray },
              { filterName: 'selectedProduct', allFilterData: productsArray },
              { filterName: 'selectedMenu', allFilterData: menusArray },
              { filterName: 'selectedStatus', allFilterData: planStatusesArray },
            ],
          }

          this.showFilterDataError(FILTERS_DATA_MAP[suffix], sessionStorageData)
        }

        // Set valid properties collected previously for Operations Historical and Data Plans. For Data Plan Details we set their default props.
        const sessionStorageProperties = !isEmpty(this.validSessionStorageProperties) ? this.validSessionStorageProperties : properties

        sessionStorageProperties.forEach(property => {
          let storedPropertyValue
          switch (true) {
            case setUrlId:
              storedPropertyValue = JSON.parse(sessionStorage.getItem(`${property}___${suffix}___${this.$route.params.id}`))
              break
            default:
              storedPropertyValue = JSON.parse(sessionStorage.getItem(`${property}___${suffix}`))
          }

          if (storedPropertyValue) {
            this[property] = storedPropertyValue
          }
        })
      },
    },
  }
}
