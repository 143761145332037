<script>
// Custom Popover implementation based on MDS Popover v3 component.
// The purpose of this modification is to watch "triggered-by" prop in order to make it work with multiple references from @mds/data-table rows.
// With this custom component the dismiss functionality is restored when clicking outside the popover, in order to close it.
// We highly recommend to use @mds/popover regular module if you only have one single reference to the popover.
import MdsPopover from '@mds/popover'

export default {
  name: 'MdsPopover',
  mixins: [MdsPopover],
  props: {
    position: {
      type: String,
      default: () => 'bottom-right',
    },
  },
  watch: {
    triggeredBy (newValue) {
      this.triggerElement = document.getElementById(newValue)
    },
  },
  methods: {
    bindEventHandlers () {
      window.addEventListener('keydown', this.keyboardEventHandler, true)
      window.addEventListener('click', this.bodyEventHandler, true)
      window.addEventListener('resize', this.debounce(this.updatePosition, 100))
      if (this.autoPosition) {
        window.addEventListener('scroll', this.debounce(this.updatePosition, 100))
      }
    },
    removeEventHandlers () {
      window.removeEventListener('keydown', this.keyboardEventHandler, true)
      window.removeEventListener('click', this.bodyEventHandler, true)
      window.removeEventListener('resize', this.updatePosition, true)

      if (this.autoPosition) {
        window.removeEventListener('scroll', this.updatePosition, true)
      }
      this.$emit('mds-popover-listeners-destroyed')
    },
  },
}
</script>
