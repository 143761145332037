<template>
  <content-container
    ref="operations-historical"
    description="View imported files and compliance processes, and perform actions on any row."
    class="operations-historical"
  >
    <template v-slot:action>
      <mds-search-field
        v-model="searchedFileName"
        label="Search by file name"
        placeholder="Search by Source / Transformed File Name"
      />
    </template>
    <mds-section
      class="operations-historical__filters-section"
      :size="6"
      collapsible
      container
      title="Filters"
      expanded
      bold
    >
      <template v-slot:mds-section-actions>
        <mds-button
          variation="flat"
          icon="refresh"
          class="operations-historical__btn-clear"
          @click="onClear"
        >
          Clear Filters
        </mds-button>
      </template>
      <mds-loader v-if="filtersLoading" />
      <div v-else>
        <mds-form
          class="operations-historical__filters"
          @submit.native.prevent="toggleQuickLinkModal()"
        >
          <mds-fieldset>
            <mds-fieldset horizontal>
              <mds-combo-box
                v-model="selectedProvider"
                label="Provider:"
                multiple
                :placeholder="filtersPlaceholder"
                :data-set="providerList"
                :multiple-item-limit="2"
              />
              <mds-combo-box
                v-model="selectedProcessType"
                label="Process Type:"
                multiple
                :placeholder="filtersPlaceholder"
                :data-set="fileTypeList"
                :multiple-item-limit="2"
              />
              <mds-date-picker
                v-model="selectedDateRange"
                label="Process Start Date:"
                format="m/d/Y"
                max-date="today"
                mode="range"
                class="operations-historical__date-picker"
              />
              <mds-combo-box
                v-model="selectedFileExtension"
                label="File Extension:"
                multiple
                :placeholder="filtersPlaceholder"
                :data-set="fileExtensionList"
                :multiple-item-limit="2"
              />
            </mds-fieldset>
            <mds-fieldset horizontal>
              <mds-combo-box
                v-model="selectedFiduciary"
                label="Fiduciary:"
                multiple
                :placeholder="filtersPlaceholder"
                :data-set="fiduciaryList"
                :multiple-item-limit="2"
              />
              <mds-combo-box
                v-model="selectedProcessMethod"
                label="Process Method:"
                multiple
                :placeholder="filtersPlaceholder"
                :data-set="deliveryMethodList"
                :multiple-item-limit="2"
              />
              <mds-combo-box
                v-model="selectedProcessStage"
                label="Process Stage:"
                multiple
                :placeholder="filtersPlaceholder"
                :data-set="processStageList"
                :multiple-item-limit="3"
              />
              <mds-combo-box
                v-model="selectedProcessStatus"
                label="Status:"
                multiple
                :placeholder="filtersPlaceholder"
                :data-set="processStatusList"
                :multiple-item-limit="3"
              />
            </mds-fieldset>
            <div class="operations-historical__save-filters">
              <p>Save this set of filters to add it to the Quick Links section of the Dashboard.</p>
              <mds-button
                variation="primary"
              >
                Save Filters
              </mds-button>
            </div>
          </mds-fieldset>
        </mds-form>
      </div>
    </mds-section>
    <div
      class="operations-historical__content"
    >
      <fade-transition>
        <mds-loader v-if="historyLoading" />
        <lazy-table
          v-else-if="tableDataValidation"
          :offset="500"
          :header-configs="tableHeaders"
          :row-data="tableData"
          :fixed-first-col="true"
          :pagination-config="tablePaginationConfig"
          pagination="below"
          class="operations-historical__table"
          @mds-data-table-page-change="handleTablePageChange"
          @toggle-action-popover="toggleActionButtonPopover($event)"
        />
        <mds-empty-state
          v-else-if="emptyStateValidation"
          title="File Not Found"
          message="There are no files that meet your search criteria."
          size="large"
          class="operations-historical__empty"
        >
          <template v-slot:mds-empty-state-icon>
            <img :src="`${publicPath}images/icons/empty-search.svg`">
          </template>
          <template
            v-if="!authErrorMessage"
            v-slot:mds-empty-state-actions
          >
            <mds-button
              variation="primary"
              type="button"
              @click="onClear"
            >
              Search Again
            </mds-button>
          </template>
        </mds-empty-state>
      </fade-transition>
    </div>
    <!--
      One single popover created outside MDS data-table to avoid create dynamically multiple popover instances.
      This takes automatically its position based on their respective button ID, which is retrieved from triggerPopover data prop.
    -->
    <mds-popover
      ref="popoverRef"
      :triggered-by="triggerPopover"
      position="bottom-right"
      title="View Historical Details"
      title-hidden
      width="200px"
    >
      <a
        :href="$router.resolve({
          name: 'operations-historical-detail',
          params: {
            id: activeCellData.rowData.historicalRecordId
          },
          query: {
            provider: activeCellData.rowData.provider.split(' ').join(''),
            type: activeCellData.rowData.fileType.split(' ').join('_').toLowerCase(),
          }
        }).href"
      >
        View Historical Details
      </a>
    </mds-popover>
    <!-- Modal  -->
    <dashboard-modal-save
      v-model="inputNewQuickLinkName"
      :toggle-modal="toggleModal"
      :disable-save-btn="disableQuickLinkSaveBtn"
      title="Create Quick Link Name"
      description="Save this set of filters to add it to the Quick Links section of the Dashboard."
      @close-modal="closeNewQuickLinkModal"
      @action-modal-button="createNewQuickLink"
    />
  </content-container>
</template>

<script>
import { format, subMonths, parseISO, addDays } from 'date-fns'
import { formatIsoFromDateString, generateRandomId } from '../../../shared/utils'
import { MdsButton } from '@mds/button'
import LazyTable from '../../components/LazyTable/LazyTable'
import MdsEmptyState from '@mds/empty-state'
import MdsForm from '@mds/form'
import MdsFieldset from '@mds/fieldset'
import MdsLoader from '@mds/loader'
import MdsSearchField from '@mds/search-field'
import MdsComboBox from '@mds/combo-box'
import { isEmpty } from 'lodash'
import MdsPopover from '../../components/Popover/Popover'
import MdsDatePicker from '../../components/Form/MdsDatePicker/MdsDatePicker'
import FadeTransition from '../../components/Transitions/FadeTransition'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import DashboardModalSave from '../../components/DashboardModalSave/DashboardModalSave'
import MdsSection from '@mds/section'
import sessionStorageMixin from '../../mixins/session-storage'
import { RadarViews } from '../../../shared/constants/radar-views'
import RoleAccess from '../../services/role-access-service'
import { CACHE_FIRST } from '../../graqhql/fetch-policies'
import {
  GET_PROVIDERS,
  GET_LIVE_PROVIDERS,
  GET_FIDUCIARIES,
  GET_IMPORTS,
  GET_IMPORTS_COUNT,
  GET_DASHBOARD_USER_ID,
} from '../../graqhql/queries'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../shared/constants/dates'
import {
  PAGE_FILTERS_MAP,
  OPERATIONS_HISTORICAL_NAME,
  PROCESS_METHODS,
  PROCESS_TYPES,
  FILE_EXTENSIONS, PROCESS_STAGES, PROCESS_STATUS,
} from '../../../shared/constants/filters'
import { VALIDATION_STATUS_COPY_MAP } from '../../../shared/constants/validation-statuses'
import { CREATE_DASHBOARD_QUICK_LINK } from '../../graqhql/mutations'

const TABLE_HEADERS = [
  {
    fieldName: 'action',
    text: 'Action',
    width: '50px',
  },
  {
    fieldName: 'provider',
    text: 'Provider',
    width: '150px',
  },
  {
    fieldName: 'fileType',
    text: 'Process Type',
    width: '150px',
    align: 'left',
  },
  {
    fieldName: 'received',
    text: 'Process Start Date',
    width: '180px',
    align: 'left',
  },
  {
    fieldName: 'fileExtension',
    text: 'File Extension',
    width: '100px',
    align: 'left',
  },
  {
    fieldName: 'deliveryMethod',
    text: 'Process Method',
    width: '120px',
    align: 'left',
  },
  {
    fieldName: 'fiduciary',
    text: 'Fiduciary',
    width: '150px',
    align: 'left',
  },
  {
    fieldName: 'processingStatus',
    text: 'Process Stage',
    width: '100px',
    align: 'left',
  },
  {
    fieldName: 'fileStatus',
    text: 'Status',
    width: '120px',
    align: 'left',
  },
]
const INITIAL_USER_ID = null
const PAGE_NAME = 'operations-historical'
const EMPTY_NEW_QUICK_LINK_NAME = ''
const ALL_PLACEHOLDER = 'All'
const INITIAL_SELECTED_PROVIDER = null
const EMPTY_SELECTED_PROVIDER = []
const INITIAL_SELECTED_FIDUCIARY = []
const INITIAL_SELECTED_PROCESS_METHOD = []
const INITIAL_SELECTED_PLAN_PROCESS_STAGE = []
const INITIAL_SELECTED_PLAN_PROCESS_STATUS = []
const INITIAL_SELECTED_PROCESS_TYPE = []
const CURRENT_DATE_FORMATTED = format(new Date(), DATE_FORMAT)
const INITIAL_SELECTED_DATE_RANGE = [format(subMonths(new Date(), 3), DATE_FORMAT), CURRENT_DATE_FORMATTED]
const INITIAL_SELECTED_FILE_EXTENSION = []
const INITIAL_SEARCHED_FILENAME = ''
const INITIAL_TRIGGER_POPOVER_HANDLER = ''
const INITIAL_ACTIVE_CELL_DATA = {
  rowData: {
    fileType: '',
    provider: '',
  },
}

// Pagination constants
const NUMBER_OF_ROWS_PER_PAGE_OPTIONS = [10, 20, 40, 80]
const NUMBER_OF_ROWS_PER_PAGE_DEFAULT = 20

// CSS custom property constants
const BACKGROUND_COLOR_VAR = 'var(--color--row-background-secondary)'

export default {
  components: {
    MdsButton,
    LazyTable,
    MdsEmptyState,
    MdsForm,
    MdsFieldset,
    MdsLoader,
    MdsComboBox,
    MdsPopover,
    MdsSearchField,
    MdsDatePicker,
    FadeTransition,
    ContentContainer,
    MdsSection,
    DashboardModalSave,
  },
  mixins: [
    sessionStorageMixin({
      properties: PAGE_FILTERS_MAP[OPERATIONS_HISTORICAL_NAME],
      suffix: OPERATIONS_HISTORICAL_NAME,
    }),
  ],
  apollo: {
    userId: {
      query: GET_DASHBOARD_USER_ID,
      fetchPolicy: CACHE_FIRST,
    },
    fiduciaries: GET_FIDUCIARIES,
    providers: {
      query: GET_PROVIDERS,
      variables () {
        return {
          selectedView: this.selectedView,
        }
      },
      error (error) {
        this.handleUnauthorizedError(error)
      },
    },
    imports: {
      query: GET_IMPORTS,
      variables () {
        return {
          filters: this.filters,
          selectedView: this.selectedView,
          pagination: this.historicalDataPagination,
        }
      },
      update: data => data.imports,
      result () {
        /**
         * The BE API endpoint for plan data is paginated which means we only request a "slice" of data at a time.
         * As a small performance improvement, we can load sibling pages behind-the-scences each time we navigate
         * to a new page and store them in the cache. This allows for the user to navigate between pages without
         * ever seeing a loader. Fortunately, Apollo handles whether or not it should grab data from the cache or
         * go to the network to fetch data that hasn't already been cached.
         *
         * The data properties "historicalDataNextPage" and "historicalDataPrevPage" below aren't actually read from. They
         * are only used so Apollo can set the data somewhere.
         */
        this.fetchAdditionalHistoricalGridPages()
      },
      skip () {
        return this.filtersLoading
      },
      error (error) {
        this.handleUnauthorizedError(error)
      },
      fetchPolicy: CACHE_FIRST,
    },
    importsCountData: {
      query: GET_IMPORTS_COUNT,
      variables () {
        return {
          filters: this.filters,
          selectedView: this.selectedView,
        }
      },
      update: data => data.importsCount,
      skip () {
        return this.filtersLoading
      },
      error (error) {
        this.handleUnauthorizedError(error)
      },
      fetchPolicy: CACHE_FIRST,
    },
  },
  data () {
    return {
      toggleModal: false,
      publicPath: process.env.BASE_URL,
      // Initialize Apollo properites
      userId: INITIAL_USER_ID,
      fiduciaries: [],
      providers: [],
      imports: { historicalData: [] },
      importsCountData: { count: 0 },
      historicalDataNextPage: null, // Property not actually read from. Apollo needs a property to set query result to.
      historicalDataPrevPage: null, // Property not actually read from. Apollo needs a property to set query result to.
      // End initialize Apollo properties
      selectedProvider: INITIAL_SELECTED_PROVIDER,
      selectedFiduciary: INITIAL_SELECTED_FIDUCIARY,
      selectedProcessMethod: INITIAL_SELECTED_PROCESS_METHOD,
      selectedProcessStage: INITIAL_SELECTED_PLAN_PROCESS_STAGE,
      selectedProcessStatus: INITIAL_SELECTED_PLAN_PROCESS_STATUS,
      selectedProcessType: INITIAL_SELECTED_PROCESS_TYPE,
      selectedDateRange: INITIAL_SELECTED_DATE_RANGE,
      selectedDateRangeFilter: INITIAL_SELECTED_DATE_RANGE,
      selectedFileExtension: INITIAL_SELECTED_FILE_EXTENSION,
      searchedFileName: INITIAL_SEARCHED_FILENAME,
      deliveryMethods: PROCESS_METHODS,
      processStages: PROCESS_STAGES,
      processStatuses: PROCESS_STATUS,
      fileTypes: PROCESS_TYPES,
      fileExtensions: FILE_EXTENSIONS,
      tableHeaders: TABLE_HEADERS,
      toggleTooltip: false,
      activeCellData: INITIAL_ACTIVE_CELL_DATA,
      triggerPopover: INITIAL_TRIGGER_POPOVER_HANDLER,
      filtersPlaceholder: ALL_PLACEHOLDER,
      tableDataPaginationData: {
        pageSize: NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: 1,
        firstItem: 1,
      },
      authErrorMessage: null,
      selectedView: RoleAccess.selectedView,
      inputNewQuickLinkName: EMPTY_NEW_QUICK_LINK_NAME,
    }
  },
  computed: {
    filtersLoading () {
      return this.$apollo.queries.fiduciaries.loading ||
        this.$apollo.queries.providers.loading
    },
    historyLoading () {
      return this.$apollo.queries.imports.loading ||
      this.$apollo.queries.importsCountData.loading
    },
    emptyStateValidation () {
      return !this.historyLoading && !this.filtersLoading && this.historicalData.length === 0
    },
    tableDataValidation () {
      return !this.historyLoading && this.historicalData.length > 0 && !this.authErrorMessage
    },
    isViewChanged () {
      return sessionStorage.getItem('isViewChanged') !== null ? JSON.parse(sessionStorage.getItem('isViewChanged')) : null
    },
    isProvidersFilterSet () {
      return sessionStorage.getItem(`selectedProvider___${OPERATIONS_HISTORICAL_NAME}`) !== null &&
        !isEmpty(JSON.parse(sessionStorage.getItem(`selectedProvider___${OPERATIONS_HISTORICAL_NAME}`)))
    },
    providerList () {
      return [...this.providers.map(({ code }) => ({ text: code, value: code }))]
    },
    deliveryMethodList () {
      return [...this.deliveryMethods.map(method => ({ text: method, value: method }))]
    },
    processStageList () {
      return [...this.processStages.map(method => ({ text: method, value: method }))]
    },
    processStatusList () {
      return [...this.processStatuses.map(method => ({ text: method, value: method }))]
    },
    fiduciaryList () {
      return [...this.fiduciaries.map(fiduciary => ({ text: fiduciary.name, value: fiduciary.code }))]
    },
    fileTypeList () {
      return [...this.fileTypes.map(fileType => ({ text: fileType, value: fileType }))]
    },
    fileExtensionList () {
      return [...this.fileExtensions.map(fileExtension => ({ text: fileExtension, value: fileExtension }))]
    },
    errorMessage () {
      return this.authErrorMessage ? this.authErrorMessage : 'There was an issue fetching historical records. Please try again.'
    },
    filters () {
      const [start, end] = this.selectedDateRangeFilter
      // Tweak to add one more day to current end date and filter the latest results
      const formattedEndDate = end === CURRENT_DATE_FORMATTED ? format(addDays(new Date(), 1), DATE_FORMAT) : end
      return {
        ...(start && formattedEndDate ? { start: formatIsoFromDateString(start, DATE_FORMAT), end: formatIsoFromDateString(formattedEndDate, DATE_FORMAT) } : {}),
        ...(this.selectedProvider?.length > 0 ? { provider: this.selectedProvider } : {}),
        ...(this.selectedProcessMethod.length > 0 ? { uploadMethod: this.selectedProcessMethod } : {}),
        ...(this.selectedProcessStage.length > 0 ? { statuses: this.selectedProcessStage } : {}),
        ...(this.selectedProcessStatus.length > 0 ? { apiResponses: this.selectedProcessStatus } : {}),
        ...(this.selectedFiduciary.length > 0 ? { fiduciary: this.selectedFiduciary } : {}),
        ...(this.selectedProcessType.length > 0 ? { fileType: this.selectedProcessType } : {}),
        ...(this.selectedFileExtension.length > 0 ? { fileExtension: this.selectedFileExtension } : {}),
      }
    },
    historicalDataPagination () {
      return {
        page: this.tableDataPaginationData.page,
        limit: this.tableDataPaginationData.pageSize,
      }
    },
    totalNumberOfRecords () {
      return this.importsCountData?.count
    },
    historicalData () {
      return this.imports?.historicalData
    },
    filteredHistory () {
      return this.historicalData.filter(item => this._applySearch(item, this.searchedFileName))
    },
    tableData () {
      return this.filteredHistory.reduce((rows, record, recordIndex) => {
        const recordRow = this._constructHistoricalDataRow(record, recordIndex)
        return [
          ...rows,
          recordRow,
        ]
      }, [])
    },
    tablePaginationConfig () {
      return {
        pageSizes: NUMBER_OF_ROWS_PER_PAGE_OPTIONS,
        pageSize: this.tableDataPaginationData.pageSize || NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: this.tableDataPaginationData.page,
        totalItems: this.totalNumberOfRecords,
        showItemsInfo: true,
        showItemsSelect: true,
      }
    },
    disableQuickLinkSaveBtn () {
      return this.inputNewQuickLinkName === EMPTY_NEW_QUICK_LINK_NAME
    },
  },
  watch: {
    selectedDateRange: {
      handler (newVal, oldVal) {
        if (newVal.length !== 1) {
          this.selectedDateRangeFilter = newVal
        } else {
          this.selectedDateRangeFilter = oldVal
        }
      },
    },
    filters () {
      // Any changes to the filters should navigate to page 1
      this.tableDataPaginationData.page = 1
      this.tableDataPaginationData.firstItem = 1
    },
  },
  mounted () {
    this.getLiveProviders()
  },
  methods: {
    async createNewQuickLink () {
      this.toggleModal = !this.toggleModal
      await this.$apollo.mutate({
        mutation: CREATE_DASHBOARD_QUICK_LINK,
        variables: {
          options: {
            userId: this.userId.id,
            quickLinkName: this.inputNewQuickLinkName,
            quickLinkData: this.getSessionStorageFiltersData(),
            page: PAGE_NAME,
            selectedView: this.selectedView,
          },
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.inputNewQuickLinkName} has been saved.`, tinted: true })
        this.closeNewQuickLinkModal()
      })
    },
    fetchAdditionalHistoricalGridPages () {
      // Abstract "sibling" page fetching logic to mixin/hook if needed elsewhere
      const query = (property, options) => this.$apollo.addSmartQuery(property, options)
      const options = (page) => ({
        query: GET_IMPORTS,
        variables: {
          filters: this.filters,
          selectedView: this.selectedView,
          pagination: { ...this.historicalDataPagination, page },
        },
        update: data => data.imports,
        fetchPolicy: CACHE_FIRST,
      })

      // Fetch the previous and next page of data
      const { page: currentPage } = this.historicalDataPagination
      query('historicalDataNextPage', options(currentPage + 1))

      if (currentPage > 1) {
        query('historicalDataPrevPage', options(currentPage - 1))
      }

      // Skip historicalDataNextPage query if there is an error from imports query
      if (this.authErrorMessage) {
        this.$apollo.queries.historicalDataNextPage.skip = true
      }
    },
    async getLiveProviders () {
      // If a user switch to Test/Live Views or there are providers in session storage, provider selection will be cleared
      if (this.isViewChanged || ((this.selectedView !== RadarViews.ALL) && !this.isProvidersFilterSet)) {
        this.selectedProvider = EMPTY_SELECTED_PROVIDER
        sessionStorage.setItem('isViewChanged', false)
        return
      }
      if ((this.selectedView === RadarViews.ALL) && !this.isProvidersFilterSet) {
        // Fetch live providers when selected view is All and is not redirected from a Quick Link
        const providerResponse = await this.$apollo.query({
          query: GET_LIVE_PROVIDERS,
          error (error) {
            this.handleUnauthorizedError(error)
          },
        })
        const liveProviderList = [...providerResponse.data.liveProviders.map(({ code }) => code)]
        // Set live providers by default only for the first time when no filter is present in session storage
        if (this.selectedProvider === INITIAL_SELECTED_PROVIDER || EMPTY_SELECTED_PROVIDER) {
          this.selectedProvider = liveProviderList
        }
      }
    },
    handleUnauthorizedError (error) {
      // Error handling for unauthorized users
      this.authErrorMessage = error?.graphQLErrors[0].message || error?.message
      this.$notifications.error({ text: this.authErrorMessage, tinted: true, persistent: true })
    },
    onClear () {
      this.selectedProvider = EMPTY_SELECTED_PROVIDER
      this.selectedFiduciary = INITIAL_SELECTED_FIDUCIARY
      this.selectedProcessMethod = INITIAL_SELECTED_PROCESS_METHOD
      this.selectedProcessStage = INITIAL_SELECTED_PLAN_PROCESS_STAGE
      this.selectedProcessStatus = INITIAL_SELECTED_PLAN_PROCESS_STATUS
      this.selectedProcessType = INITIAL_SELECTED_PROCESS_TYPE
      this.selectedDateRange = INITIAL_SELECTED_DATE_RANGE
      this.selectedFileExtension = INITIAL_SELECTED_FILE_EXTENSION
      this.searchedFileName = INITIAL_SEARCHED_FILENAME
    },
    handleTablePageChange (data) {
      this.tableDataPaginationData = data
      // Scroll to "top" of page
      this.$refs['operations-historical'].$el.scrollIntoView()
    },
    _constructHistoricalDataRow (record, recordIndex) {
      return {
        id: `historical-record-${record.id}-${generateRandomId()}`,
        historicalRecordId: record.id,
        provider: record.provider,
        received: record.createdDate ? format(parseISO(record.createdDate), DATE_TIME_FORMAT) : '—',
        fiduciary: record.fiduciary || '—',
        fileType: record.fileType || '—',
        fileExtension: record.sourcefiles?.length ? record.sourcefiles[0].name.split('.').pop().toUpperCase() : FILE_EXTENSIONS[0],
        deliveryMethod: this._displayDeliveryMethod(record),
        fileStatus: record.validationStatus ? VALIDATION_STATUS_COPY_MAP[record.validationStatus] : '—',
        processingStatus: record.processingStatus || '—',
        class: 'operations-historical__table-row operations-historical__table-row--primary',
        // Custom "zebraing" so all rows of the same plan are grouped together
        ...(recordIndex % 2 ? { style: { backgroundColor: BACKGROUND_COLOR_VAR } } : {}),
      }
    },
    _applySearch (item, searchedValue) {
      if (!searchedValue) {
        return true
      }
      return item.fileName.toLowerCase().includes(searchedValue.toLowerCase()) ||
        (item.sourcefiles?.length && item.sourcefiles[0].name.toLowerCase().includes(searchedValue.toLowerCase()))
    },
    _displayDeliveryMethod (item) {
      const { uploadMethod, createdBy } = item
      return uploadMethod === 'Radar' ? `${uploadMethod} - ${createdBy}` : uploadMethod
    },
    toggleActionButtonPopover ({ cellData, triggerPopover }) {
      this.activeCellData = cellData
      this.triggerPopover = triggerPopover
      if (!this.$refs.popoverRef.isVisible) {
        this.$refs.popoverRef.show()
      } else {
        this.$refs.popoverRef.hide()
      }
    },
    closeNewQuickLinkModal () {
      this.toggleModal = false
      this.inputNewQuickLinkName = EMPTY_NEW_QUICK_LINK_NAME
    },
    toggleQuickLinkModal () {
      this.toggleModal = !this.toggleModal
    },
  },
}
</script>

<style lang="scss">

@mixin status-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &::before {
    content: '';
    width: 5px;
    height: 5px;
    margin-right: $mds-space-half-x;
    border-radius: $mds-border-radius-round;
    background-color: var(--status-color);
  }
}

.operations-historical__save-filters{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $mds-space-3-x;
    p {
      margin: 0;
    }
  }

.operations-historical__filters-section {
  padding: $mds-space-2-and-a-half-x $mds-space-3-x;
  border-radius: $mds-border-radius-panel;
  margin-bottom: $mds-space-3-and-a-half-x;
}

.operations-historical__btn-clear {
  padding: 0;
}

.operations-historical {
  --color--success: #009592;
  --color--partial: #{$mds-feedback-color-warning};
  --color--failed: #{$mds-feedback-color-error};
  --color--schema-failed: #{$mds-color-violet-46};
}

.operations-historical__content {
  align-items: center;
}

.operations-historical__loader {
  padding: $mds-space-8-x 0;
}

.operations-historical__table-status {
  @include status-indicator(); // Status type set inline above
}
</style>

<style lang="scss" scoped>
// overlap popover over action column buttons
.mds-popover--visible___radar {
   z-index: 9999;
}

// the top must be initialized to avoid style errors
.mds-popover___radar, .mds-tooltip___radar  {
  top: 0;
}

.operations-historical {
  --color--row-background-secondary: #{$mds-background-color-light-gray};
}

// Scoped CSS for Search Field
.content-container__action {
  .mds-search-field___radar {
    min-width: 300px;
  }
}
</style>

<style lang="scss" module>
// MDS overrides
@import '@mds/link';

:global(.operations-historical__filters) {
  .mds-fieldset__horizontal > .mds-label {
    flex: 1 0 0;
    margin-bottom: 0;
  }
  .mds-fieldset__horizontal {
    .mds-combo-box {
      .mds-combo-box__result-list {
        z-index: 9999;
      }
    }
  }
  .mds-fieldset {
    flex-grow: 2;
    margin-bottom: $mds-space-2-x;
  }
}

:global(.operations-historical__table) {
  // overlap action column buttons to make automation tests work
  .mds-data-table--fixed-column {
    z-index: 99;
  }
  .mds-data-table__cell {
    button {
      z-index: 999;
    }
    a {
      @include mds-link($in-table: true, $visited-styles: false);
    }
  }
}

:global(.operations-historical__empty) {
  margin: auto;
  .mds-empty-state__icon > * {
    max-width: 100%;
  }
}

:global(.operations-historical) {
  .mds-combo-box {
    .mds-combo-box__multiple-select {
      .mds-combo-box__selected-list {
        max-height: 29px; // Fix double height in combo-box filters
      }
    }
  }
}
</style>
